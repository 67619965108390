 
.usa-info-header-button{
  background-color:#0080FF;
}

.usa-info-header-button:hover{
  background-color:#004080;
}

.highlighted-element{
  box-sizing: border-box;
  outline: 1px dashed red;
  /* background-color: rgba(184, 192, 184, 0.5) !important; */
}